import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Error from './Error'
import axios from 'axios';
import Swal from 'sweetalert2';
import {BACKEND_URL} from '../config'

class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        let loggedIn = false;
        let loginStatus = 0;
        let Success = 0
        this.state = {
            username: '',
            password: '',
            loggedIn,
            usernameError: '',
            passwordError: '',
            loginError: ''
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    Change = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async onSubmit(e) {
        e.preventDefault();
        e.persist();
        const { username, password } = this.state

        if (username === '' || !username.match(/^[A-Za-z0-9]*$/)) {
            this.setState({
                usernameError: 'Please enter valid username'
            })
            return;
        } else {
            this.setState({
                usernameError: ''
            })
        }


        if (password === '') {
            this.setState({
                passwordError: 'Please enter correct password'
            })
            return;
        } else {
            this.setState({
                passwordError: ''
            })
        }
        const user = {
            user_name: this.state.username,
            password: this.state.password
        }
        try {
            const res = await axios.post(BACKEND_URL + '/users/adminLogin/', user);
            if (res.data.Success === 1) {
                Swal.fire("Logged in successfully!"
            );
                localStorage.setItem("token2", "3424732dhakjfkjdafshi4128hdjsh8923");
                this.setState({ loggedIn: true });
                this.props.history.push('/AdminPanel');
            } else {
                this.setState({
                    loginError: "Invalid username or password"
                });
            }
        } catch (err) {
            console.log(err);
            this.setState({
                loginError: 'Error logging in, please try again.'
            });
        }
        
    }

    render() {
        // if (this.state.loggedIn) {
        //     return (
        //         <Redirect to="/AdminPanel" />
        //     )
        // }
        return (
            <div className="App">
                <Header title="SDS Portal" type="login"></Header>
                <div className="mt-4 d-flex justify-content-center align-items-center login-div flex-column">
                    <form className="form-group login-form d-flex flex-column" onSubmit={this.onSubmit}>
                        <span className="h3 mb-3 align-self-center text-uppercase">Login</span>
                        <div className="list mt-3 mb-5 d-flex justify-content-center align-items-center ml-3">
                            <ul className="d-flex flex-row list-group">
                                <li className="list-group-item px-5">
                                    <Link to="/" className="">User</Link>
                                </li>
                                <li className="list-group-item list-group-item-primary px-5">
                                    <Link to="/adminLogin">Admin</Link>
                                </li>
                            </ul>
                        </div>
                        <input
                            type="text"
                            placeholder="Username"
                            id="username"
                            onChange={this.Change}
                            name="username"
                            className="form-control"></input>
                        <Error error={this.state.usernameError}></Error>
                        <div className="sizedbox mb-3"></div>
                        <input
                            type="password"
                            placeholder="Password"
                            onChange={this.Change}
                            name="password"
                            className="form-control"></input>
                        <Error error={this.state.passwordError}></Error>
                        <div className="sizedbox mb-3"></div>
                        <input type="submit" value="Submit" className="btn btn-dark text-center align-self-center" />
                        <center><Error error={this.state.loginError} className="mb-3"></Error></center>
                        {/* <Link to="/AdminPanel" className="align-self-center mt-5 text-secondary">Admin Panel</Link> */}
                        {/* <Link to="/" className="align-self-center mt-5 text-secondary">Forgot password ? </Link> */}
                    </form>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default AdminLogin;