import React from 'react';
// import COEP from '../COEP.png'
// import SDS from '../SDS.png'
function HeaderNew(props) {
    // if (props.type === "form") {
    //     return (
    //         <nav className="navbar navbar-dark bg-dark d-flex">
    //             <Link /*to="/" */ className="navbar-brand mb-o h1">{props.title}</Link>
    //             <Link to="" className="text-light pr-3">Logout</Link>
    //         </nav>
    //     )
    // }
    return (
        <nav className="navbar navbar-dark bg-dark d-flex flex-row">
            {/* <img src={COEP} style={{width:'60px'}}></img> */}
            <h1 /*to="/" */ className="navbar-brand mb-o h1" style={{fontSize: '30px'}}>{props.title}</h1>
            {/* <img src={SDS} style={{width:'60px'}}></img> */}
        </nav>
    );
}

export default HeaderNew;