import React from "react";
import COEP from "../COEP.png";
import SDS from "../SDS.png";
import '../App.css'
function Header(props) {
    // if (props.type === "form") {
    //     return (
    //         <nav className="navbar navbar-dark bg-dark d-flex">
    //             <Link /*to="/" */ className="navbar-brand mb-o h1">{props.title}</Link>
    //             <Link to="" className="text-light pr-3">Logout</Link>
    //         </nav>
    //     )
    // }
    return (
        <nav
            className="navbar navbar-dark bg-dark d-flex flex-row"
            // style={{ width: "96%" }}
        >
            <img src={COEP} className="header-img"></img>
            <h1
                /*to="/" */ className="navbar-brand mb-o h1"
                style={{ fontSize: "30px" }}
            >
                {props.title}
            </h1>
            <img src={SDS} className="header-img"></img>
        </nav>
    );
}

export default Header;
