import React from "react";
import { Redirect, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Error from "./Error";
class Login extends React.Component {
    constructor(props) {
        super(props);
        let loggedIn = false;
        this.state = {
            username: "",
            password: "",
            loggedIn,
            usernameError: "",
            passwordError: "",
            loginError: false
        };
    }

    Change = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onSubmit = e => {
        e.preventDefault();
        e.persist();
        this.setState({
            loggedIn: true
        });
    };

    render() {
        if (this.state.loggedIn) {
            return <Redirect to="/form" />;
        }
        return (
            <div className="App">
                <Header title="SDS Portal" type="login"></Header>
                <div className="d-flex justify-content-center align-items-center login-div flex-column">
                    <form
                        className="form-group login-form d-flex flex-column"
                        style={{ paddingBottom: "3em" }}
                        onSubmit={this.onSubmit}
                    >
                        <span className="h3 mb-3 align-self-center text-uppercase">
                            Login
                        </span>
                        <div className="list mt-3 mb-5 d-flex justify-content-center align-self-center align-items-center ml-3">
                            <ul className="d-flex flex-row list-group">
                                <li className="list-group-item list-group-item-primary my-list-item">
                                    <Link to="/" className="">
                                        User
                                    </Link>
                                </li>
                                <li className="list-group-item my-list-item">
                                    <Link to="/adminLogin">Admin</Link>
                                </li>
                            </ul>
                        </div>
                        {/* <input
                            type="text"
                            placeholder="Username"
                            id="username"
                            onChange={this.Change}
                            name="username"
                            className="form-control"></input>
                        <Error error={this.state.usernameError}></Error>
                        <div className="sizedbox mb-3"></div>
                        <input
                            type="password"
                            placeholder="Password"
                            onChange={this.Change}
                            name="password"
                            className="form-control"></input>
                        <Error error={this.state.passwordError}></Error>
                        <div className="sizedbox mb-3"></div> */}

                        <input
                            type="submit"
                            value="Request a Project"
                            className="btn btn-dark text-center align-self-center"
                            style={{ margin: "3em 0" }}
                        ></input>
                        {/* <button 
                        onClick={this.nextPage}
                        >
                            Request a Project
                        </button> */}
                        {/* <center><Error error={this.state.loginError} className="mb-3"></Error></center> */}
                        {/* <Link to="/AdminPanel" className="align-self-center mt-5 text-secondary">Admin Panel</Link> */}
                        {/* <Link to="/" className="align-self-center mt-5 text-secondary">Forgot password ? </Link> */}
                    </form>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Login;
