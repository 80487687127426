import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import Login from './Components/Login'
import Form from './Components/Form'
import AdminPanel from './Components/AdminPanel'
import DetailV from './Components/DetailV'
import nonApproved from './Components/Non-Approved Projects'
import Admin from './Components/Admin'
import AdminLogin from './Components/AdminLogin'
// import nonApproved from './Components/Non-Approved Projects';
function App() {
  return (
    <div className="App">
      <Router basename="/sdsportal">
      <Switch>
        <Route exact path = "/adminLogin" component = {AdminLogin}></Route>
        <Route exact path = "/form" component={Form}></Route>
        <Route path = "/AdminPanel" component={AdminPanel}></Route>
        <Route path = "/Admin/:id" component={Admin}></Route>
        <Route path = "/DetailV/:id" component={DetailV}></Route>
        <Route exact path="/nonApproved" component={nonApproved}></Route>
        <Route path = '/' component={Login}></Route>
      </Switch>
      </Router>
    </div>
  );
}

export default App;
