import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import HeaderNew from "./HeaderNew";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Footer from "./Footer";
import axios from "axios";
import Error from "./Error";
import { BACKEND_URL } from "../config";

class DetailV extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token2");
    let loggedIn = false;
    let loggedOut = false;
    let updateState = false;
    if (token === "3424732dhakjfkjdafshi4128hdjsh8923") {
      loggedIn = true;
    }
    this.state = {
      fname: "",
      email: "",
      contact: "",
      app_details: "",
      method: "",
      requirement: "",
      user: "",
      deadline: new Date(),
      meetDate: new Date(),
      purpose: "",
      leader: "",
      status: "Pending",
      team: "",
      checkList: [false, false, false, false],
      loggedIn,
      loggedOut,
      updateState,
      tasks: [],
      id: "",
      errors: {
        fname: "",
        email: "",
        contact: "",
        purpose: "",
        app_details: "",
        method: "",
        deadline: "",
        meetDate: "",
        leader: "",
        status: "",
        team: "",
      },
    };
  }
  async componentDidMount() {
    // if (Number(srno)) {
    const { id } = this.props.match.params;
    await axios.get(BACKEND_URL + "/tasks/detail/" + id).then((res) => {
      this.setState({
        fname: res.data.faculty_name,
        email: res.data.email,
        contact: res.data.contact,
        app_details: res.data.app_details,
        method: res.data.method,
        status: res.data.status,
        requirement: res.data.requirement,
        user: res.data.user,
        leader: res.data.leader,
        team: res.data.team,
        deadline: new Date(res.data.deadline),
        meetDate: new Date(res.data.meetDate),
        purpose: res.data.purpose,
        id: id,
      });
      var new2 = res.data.method.split(", ");
      console.log(new2);
      for (var i = 0; i < new2.length; i++) {
        if (new2[i] === "Website") {
          var newList = this.state.checkList;
          newList[0] = true;
          this.setState({
            checkList: newList,
          });
        } else if (new2[i] === "Web App") {
          var newList = this.state.checkList;
          newList[1] = true;
          this.setState({
            checkList: newList,
          });
        } else if (new2[i] === "Mobile App") {
          var newList = this.state.checkList;
          newList[2] = true;
          this.setState({
            checkList: newList,
          });
        } else if (new2[i] === "Others") {
          var newList = this.state.checkList;
          newList[3] = true;
          this.setState({
            checkList: newList,
          });
        }
      }
    });
    // }
  }
  //To handle all the values
  changeHandler = (event) => {
    var oldvalue = "",
      value = "";
    if (event.target.name === "method") {
      oldvalue = this.state.method;
      value = event.target.value;
      if (oldvalue !== "") {
        value += ", ";
      }
      value += oldvalue;
      if (value !== "") {
        this.setState({
          [event.target.name]: value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  //To handle the deadline date
  handleDeadlineDate = (date) => {
    this.setState({
      deadline: date,
    });
  };

  //To handle the meet date
  handleMeetDate = (date) => {
    this.setState({
      meetDate: date,
    });
  };

  //Submit handler
  onSubmit = (e) => {
    e.preventDefault();
    e.persist();

    //Username Validations
    if (
      this.state.fname.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/) ===
        null ||
      this.state.fname === null
    ) {
      this.setState({
        errors: { fname: "Enter a valid name" },
      });
      return;
    } else {
      this.setState({
        errors: { fname: "" },
      });
    }

    //Email Validation
    if (
      this.state.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      ) === null ||
      this.state.email === null
    ) {
      this.setState({
        errors: { email: "Enter a valid email address" },
      });
      return;
    } else {
      this.setState({
        errors: { email: "" },
      });
    }

    //Contact Validation
    if (this.state.contact === null || this.state.contact.length !== 10) {
      this.setState({
        errors: { contact: "Enter a valid phone number" },
      });
      return;
    } else {
      this.setState({
        errors: { contact: "" },
      });
    }

    //Major Purpose Validations
    if (
      this.state.purpose.match(/^(.|\s)*[a-zA-Z]+(.|\s)*$/) === null ||
      this.state.purpose === null
    ) {
      this.setState({
        errors: { purpose: "Enter a valid purpose of app" },
      });
      return;
    } else {
      this.setState({
        errors: { purpose: "" },
      });
    }

    //Application Details Validation
    if (
      this.state.app_details.match(/^(.|\s)*[a-zA-Z]+(.|\s)*$/) === null ||
      this.state.app_details === null
    ) {
      this.setState({
        errors: { app_details: "Enter a valid app details" },
      });
      return;
    } else {
      this.setState({
        errors: { app_details: "" },
      });
    }

    //Preferred Method Validation
    if (this.state.method === "") {
      this.setState({
        errors: { method: "Please select atleast one preferred method" },
      });
      return;
    } else {
      this.setState({
        errors: { method: "" },
      });
    }
    // var g1 = new Date();
    // var g2 = g1.getTime() - 10000000;
    // //Meet Date Validation
    // if (g2 >= this.state.meetDate.getTime()) {
    //     this.setState({
    //         errors: { meetDate: 'Please enter valid meet date' }
    //     })
    //     return;
    // } else {
    //     this.setState({
    //         errors: { meetDate: '' }
    //     })
    // }
    const task = {
      faculty_name: this.state.fname,
      email: this.state.email,
      contact: this.state.contact,
      app_details: this.state.app_details,
      method: this.state.method,
      requirement: this.state.requirement,
      user: this.state.user,
      deadline: this.state.deadline,
      meetDate: this.state.meetDate,
      purpose: this.state.purpose,
      leader: this.state.leader,
      status: this.state.status,
      team: this.state.team,
    };
    // if (this.state.id) {
    axios
      .put(BACKEND_URL + "/tasks/update/" + this.state.id, task)
      .then((res) => 
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        })
      )
      .catch((err) => console.log(err));
    this.setState({
      updateState: true,
    });
    // }
    // else {
    //     axios.post('http://localhost:5000/tasks/', task)
    //         .then(res => alert('Task Added'))
    //         .catch(err => console.log(err));
    // }
  };

  //Utility function for logout
  logout = () => {
    localStorage.removeItem("token2");
    localStorage.removeItem("index");
    localStorage.removeItem("count");
    this.setState({
      loggedOut: true,
      id: "",
    });
    //this.props.history.pop();
  };
  render() {
    if (this.state.loggedIn === false || this.state.loggedOut === true) {
      return <Redirect to="/adminLogin" />;
    } else if (this.state.updateState) {
      return (
        <div>
          <Redirect to="/AdminPanel" />
        </div>
      );
    }
    return (
      <div className="App">
        <HeaderNew title="Details" />
        <span className="logout text-light" onClick={this.logout}>
          Logout
        </span>
        <div className="form-css">
          <form
            className="align-content-center form-group d-flex flex-column px-5 py-4"
            onSubmit={this.onSubmit}
          >
            <label>
              <b>Name</b>
            </label>
            <input
              type="text"
              name="fname"
              className="form-control border-dark  form-text"
              value={this.state.fname}
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.fname}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Email Id</b>
            </label>
            <input
              type="email"
              name="email"
              className="form-control border-dark form-text"
              value={this.state.email}
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.email}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Contact Number</b>
            </label>
            <input
              type="number"
              name="contact"
              className="form-control border-dark form-text "
              value={this.state.contact}
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.contact}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Major Purpose</b>
            </label>
            <input
              type="text"
              name="purpose"
              className="form-control border-dark form-text "
              value={this.state.purpose}
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.purpose}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Application Details:</b>
              <br></br>
              <textarea
                rows="5"
                cols="60"
                name="app_details"
                value={this.state.app_details}
                className="form-control border-dark form-text "
                onChange={this.changeHandler}
                style={{ marginTop: "0.8%" }}
              />
            </label>
            <Error error={this.state.errors.app_details}></Error>
            {/* <div className="sizedBox mb-4"></div>
                        <label><b>Preferred Method</b></label>
                        <textarea
                            rows="1"
                            cols="55"
                            name='method'
                            value={this.state.method}
                            className="form-control border-dark  "
                            onChange={this.changeHandler}
                            style={{marginTop: "0.8%"}}
                        /> */}
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Preferred Method</b>
            </label>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Website"
                checked={this.state.checkList[0]}
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Website</span>
            </div>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Web App"
                checked={this.state.checkList[1]}
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Web App</span>
            </div>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Mobile App"
                checked={this.state.checkList[2]}
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Mobile App</span>
            </div>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Others"
                checked={this.state.checkList[3]}
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Others</span>
            </div>
            <Error error={this.state.errors.method}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Meeting Date</b> <br></br>
              <DatePicker
                selected={this.state.meetDate}
                onChange={this.handleMeetDate}
                className="form-control border-dark form-text "
                style={{ marginTop: "0.8%" }}
              />
            </label>
            <Error error={this.state.errors.meetDate}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Requirements</b>
              <br></br>
              <textarea
                rows="3"
                cols="60"
                name="requirement"
                value={this.state.requirement}
                className="form-control border-dark form-text "
                onChange={this.changeHandler}
                style={{ marginTop: "0.8%" }}
              />
            </label>
            <Error error={this.state.errors.requirement}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>User</b>
              <select
                rows="1"
                cols="55"
                value={this.state.user}
                className="form-control border-dark form-text "
                style={{ marginTop: "0.8%" }}
                onChange={this.changeHandler}
                name="user"
              >
                <option>Student</option>
                <option>Professor</option>
                <option>Others</option>
              </select>
            </label>
            <Error error={this.state.errors.user}></Error>
            <div className="sizedBox mb-4"></div>

            <label>
              <b>Deadline</b> <br></br>
              <DatePicker
                selected={this.state.deadline}
                onChange={this.handleDeadlineDate}
                className="form-control border-dark col-md-12 form-text"
                style={{ marginTop: "0.8%" }}
              />
            </label>
            <Error error={this.state.errors.deadline}></Error>
            <div className="sizedBox "></div>
            <br></br>
            <label>
              <b>Project Leader</b>
            </label>
            <input
              type="text"
              name="leader"
              className="form-control border-dark mb-4  form-text"
              value={this.state.leader}
              onChange={this.changeHandler}
              style={{ marginTop: "0.8%" }}
            />
            <label>
              <b>Status</b>
              <select
                value={this.state.status}
                className="form-control border-dark col-md-4 form-text"
                onChange={this.changeHandler}
                style={{ marginTop: "0.8%" }}
                name="status"
              >
                <option>Pending</option>
                <option>Implemented</option>
                <option>Requested</option>
              </select>
            </label>
            <Error error={this.state.errors.status}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Team Members</b>
              <br></br>
              <textarea
                rows="6"
                cols="50"
                name="team"
                value={this.state.team}
                className="form-control border-dark  form-text"
                onSelect={this.handleSelect}
                onChange={this.changeHandler}
                style={{ marginTop: "0.8%" }}
              />
            </label>
            <div className="sizedBox mb-4"></div>

            <input
              type="submit"
              value="Submit"
              className="btn btn-dark col-md-3"
            />
          </form>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
export default DetailV;
