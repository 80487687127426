import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BACKEND_URL} from '../config'
import Swal from 'sweetalert2';

class AdminPanel extends React.Component {
	cancelTokenSource = axios.CancelToken.source(); // Create a cancel token
	constructor(props) {
		super(props)
		const token2 = localStorage.getItem("token2")
		let loggedIn = false
		if(token2 == "3424732dhakjfkjdafshi4128hdjsh8923") {
			loggedIn = true
		}
		let checkMount = false;
		let count = 0;
		this.state = {
			tasks: [],
			del: '',
			notify: '',
			loggedIn
		}
	}
	componentDidMount() {
		this.checkMount = true; // Set when the component is mounted
		this.getData();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.tasks.length !== prevState.tasks.length) {
			this.notify = '';
			this.checkMount = true;
		}
	}
	async getData() {
		await axios.get(BACKEND_URL + `/tasks/`)
			.then(res => {
				if (this.checkMount) {
					this.setState({
						tasks: res.data,
					});
				}
			})
			.catch(err => console.log(err))
	}
	componentWillUnmount() {
		this.checkMount = false; // Unset when the component is unmounted
	}
	showIndex = (index) => {
		localStorage.setItem("count", index + 1);
		return ++index;
	}
	checkDays = (deadLine, today) => {
		var Difference_In_Time = deadLine.getTime() - today.getTime();
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
		return Difference_In_Days;
	}
	returnDate = (Today) => {
		var todayDD = String(Today.getDate()).padStart(2, '0');
		var todayMM = String(Today.getMonth() + 1).padStart(2, '0');
		var todayYYYY = Today.getFullYear();
		Today = todayYYYY + '-' + todayMM + '-' + todayDD;
		return Today;
	}
	checkColor = (count) => {
		var color = "red";
		if (this.state.tasks) {
			// var deadLine = this.state.tasks.map(tasks => tasks.deadline);
			var status = this.state.tasks.map(tasks => tasks.status);
			// var newYYYY = deadLine[count].slice(0, 4);
			// var newMM = deadLine[count].slice(5, 7);
			// var newDD = deadLine[count].slice(8, 10);
			// var newToday = new Date(newMM + "/" + newDD + "/" + newYYYY);
			// var Days = this.checkDays(newToday, new Date(this.returnDate(new Date())));
			// var ID = this.state.tasks.map(tasks => tasks._id);
			// ID = ID[count];
			// var check = this.returnDate(newToday);
			if (!status[count].localeCompare("Pending"))
				color = "red"
			else if (!status[count].localeCompare('Implemented'))
				color = "green"
			else
				color = '#2980b9'
		}
		return color;
	}
	changeComponent = (index) => {
		// localStorage.setItem("token", "dshfajkd");
		// console.log(this.state.tasks[index])
		localStorage.setItem("index", this.state.tasks[index]._id);
		this.props.history.push(`/detailV/${this.state.tasks[index]._id}`);
		// <Redirect to={"/detailV/" + this.state.tasks[index]._id} />
		this.forceUpdate();
	}
	showData = (tasks) => {
		return tasks.map((tasks, index) =>
			<tr key={index}>
				<td>{this.showIndex(index)}</td>
				<td style={{ backgroundColor: this.checkColor(index), color: 'white' }}>{tasks.status}</td>
				<td>{tasks.faculty_name}</td>
				<td><Link to={`/Admin/${tasks._id}`}>{tasks.app_details}</Link></td>
				<td>{new Date(tasks.deadline).toLocaleDateString()}</td>
				<td className="text-center" onClick={() => this.deleteData(index)}><i className="fa fa-trash text-danger h3"></i></td>
				<td className="text-center" onClick={() => this.changeComponent(index)}><i className="fa fa-edit h3 text-success"></i></td>
			</tr>
		)
	}
	onChangeData = (event) => {
		this.setState({
			del: event.target.value
		});
	}
	

	async deleteData(index) {
	if (index < this.state.tasks.length && index >= 0) {
		const taskId = this.state.tasks[index]._id;

		try {
		// Show confirmation dialog
		const result = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
		});

		if (result.isConfirmed) {
			// Optimistically update the UI by removing the task immediately
			const updatedTasks = this.state.tasks.filter((_, i) => i !== index);
			this.setState({ tasks: updatedTasks });

			// Attempt to delete on the server
			await axios.delete(`${BACKEND_URL}/tasks/${taskId}`);

			// Show success alert
			Swal.fire({
			icon: 'success',
			title: 'Deleted!',
			text: 'The project has been deleted.',
			confirmButtonText: 'OK',
			});
		}
		} catch (err) {
		console.error('Error deleting task:', err);

		// Rollback the task deletion in case of error
		this.setState({ tasks: this.state.tasks });

		// Show error alert
		Swal.fire({
			icon: 'error',
			title: 'Error',
			text: 'Failed to delete the task. Please try again.',
			confirmButtonText: 'OK',
		});
		}
	    }
	}

	
	logout = () => {
		localStorage.removeItem("token2")
		localStorage.removeItem("index");
		localStorage.removeItem("count")
		this.setState({
			loggedOut: true,
			id: ''
		})
	}
	render() {
		if (this.state.loggedIn === false || this.state.loggedOut === true) {
			return (
				<Redirect to="/adminLogin" />
			)
		} else if (this.state.updateState) {
			return (
				<Redirect to="/AdminPanel"></Redirect>
			)
		}
		return (
			<div className="App">
				<Header title="Admin Panel" />
				<Link className="logout text-light" onClick={this.logout}>Logout</Link>
				<ul className="d-flex flex-row list-group mt-3 ml-3 mb-3">
					<li className="list-group-item list-group-item-primary">
						<Link to="/AdminPanel" className="">All Projects</Link>
					</li>
					<li className="list-group-item">
						<Link to="/nonApproved">Non Approved Projects</Link>
					</li>
				</ul>
				{/* <h2 style={{ marginTop: '1rem', marginBottom: '1rem' }} className="ml-3">Projects</h2> */}
				<Link to="/nonApproved" style={{ background: 'rgba(57, 255, 20, 0.6)', fontSize: '18px', margin: '0em 1em 1em 1em' }}>{this.state.notify}</Link>
				<div className="table-responsive tableFixHead" style={{ height: '28rem', marginBottom: '1.2rem', width: 'auto', marginLeft: '4.2rem', marginRight: '4.2rem' }}>
					<table className="table table-striped table-bordered">
						<thead className="thead-dark">
							<tr>
								<th>Sr No.</th>
								<th>Status</th>
								<th>Faculty Name</th>
								<th>App Details</th>
								<th>Deadline</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.showData(this.state.tasks)}
						</tbody>
					</table>
				</div>

				<Footer />
			</div>
		)
	}
}

export default AdminPanel;
