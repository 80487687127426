import React from 'react';
// import { Link, Redirect } from 'react-router-dom';
import HeaderNew from './HeaderNew';
import Footer from './Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {BACKEND_URL} from '../config'

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            email: '',
            contact: '',
            app_details: '',
            method: '',
            requirement: '',
            user: 'Student',
            deadline: new Date(),
            meetDate: new Date(),
            purpose: '',
            team: '',
            leader: ''
        }
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        axios.get(BACKEND_URL + "/tasks/detail/" + id)
            .then(res => this.setState({
                fname: res.data.faculty_name,
                email: res.data.email,
                contact: res.data.contact,
                app_details: res.data.app_details,
                method: res.data.method,
                requirement: res.data.requirement,
                user: res.data.user,
                deadline: res.data.deadline,
                meetDate: res.data.meetDate,
                purpose: res.data.purpose,
                team: res.data.team,
                leader: res.data.leader
            }));
    }
    render() {
        return (
            <div className="App">
                <HeaderNew title="App Details" />
                <div className="container d-flex justify-content-center align-items-center login-div">
                    <div className="box d-flex flex-column login-form" style={{padding:'50px',fontSize: '16px'}}>
                            <label><b>Requested By:  </b> {this.state.fname}</label>
                            <label><b>Email Id:  </b> {this.state.email}</label>
                            <label><b>Contact Number:  </b> {this.state.contact}</label>
                            <label><b>Detail:  </b>{this.state.app_details}</label>
                            <label><b>Preferred Method:  </b>{this.state.method}</label>
                            <label><b>Specific Requirements:  </b> {this.state.requirement}</label>
                            <label><b>User:  </b> {this.state.user}</label>
                            <label><b>Deadline:  </b> {new Date(this.state.deadline).toUTCString()}</label>
                            <label><b>Meeting Date:  </b>{new Date(this.state.meetDate).toUTCString()}</label>
                            <label><b>Purpose:  </b> {this.state.purpose}</label>
                            <label><b>Team :  </b>{this.state.team}</label>
                            <label><b>Leader:  </b>{this.state.leader}</label>
                        <Link to="/AdminPanel" className="btn btn-dark col-md-3">Back</Link>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Admin;
