import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Header from "./Header";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Footer from "./Footer";
import axios from "axios";
import Error from "./Error";
import { BACKEND_URL } from "../config";

class Form extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let loggedIn = true;
    let loggedOut = false;
    let updateState = false;
    // if (token == null) {
    //     loggedIn = false
    // }
    this.state = {
      fname: "",
      email: "",
      contact: "",
      app_details: "",
      method: "",
      requirement: "",
      user: "Student",
      deadline: new Date(),
      meetDate: new Date(),
      purpose: "",
      loggedIn,
      loggedOut,
      updateState,
      tasks: [],
      id: "",
      redirectToHome: false,
      errors: {
        fname: "",
        email: "",
        contact: "",
        purpose: "",
        app_details: "",
        method: "",
        deadline: "",
        meetDate: "",
      },
    };
  }
  //To handle all the values
  changeHandler = (event) => {
    var oldvalue = "",
      value = "";
    if (event.target.name === "method") {
      oldvalue = this.state.method;
      value = event.target.value;
      if (oldvalue !== "") {
        value += ", ";
      }
      value += oldvalue;
      if (value !== "") {
        this.setState({
          [event.target.name]: value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  //To handle the deadline date
  handleDeadlineDate = (date) => {
    this.setState({
      deadline: date,
    });
  };

  //To handle the meet date
  handleMeetDate = (date) => {
    this.setState({
      meetDate: date,
    });
  };

  //Submit handler
  onSubmit = (e) => {
    e.preventDefault();
    e.persist();

    //Username Validations
    if (
      this.state.fname.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/) ===
        null ||
      this.state.fname === null
    ) {
      this.setState({
        errors: { fname: "Enter a valid name" },
      });
      return;
    } else {
      this.setState({
        errors: { fname: "" },
      });
    }

    //Email Validation
    if (
      this.state.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      ) === null ||
      this.state.email === null
    ) {
      this.setState({
        errors: { email: "Enter a valid email address" },
      });
      return;
    } else {
      this.setState({
        errors: { email: "" },
      });
    }

    //Contact Validation
    if (this.state.contact === null || this.state.contact.length !== 10) {
      this.setState({
        errors: { contact: "Enter a valid phone number" },
      });
      return;
    } else {
      this.setState({
        errors: { contact: "" },
      });
    }

    //Major Purpose Validations
    if (
      this.state.purpose.match(/^(.|\s)*[a-zA-Z]+(.|\s)*$/) === null ||
      this.state.purpose === null
    ) {
      this.setState({
        errors: { purpose: "Enter a valid purpose of app" },
      });
      return;
    } else {
      this.setState({
        errors: { purpose: "" },
      });
    }

    //Application Details Validation
    if (
      this.state.app_details.match(/^(.|\s)*[a-zA-Z]+(.|\s)*$/) === null ||
      this.state.app_details === null
    ) {
      this.setState({
        errors: { app_details: "Enter a valid app details" },
      });
      return;
    } else {
      this.setState({
        errors: { app_details: "" },
      });
    }

    //Preferred Method Validation
    if (this.state.method === "") {
      this.setState({
        errors: { method: "Please select atleast one preferred method" },
      });
      return;
    } else {
      this.setState({
        errors: { method: "" },
      });
    }
    var g1 = new Date();
    var g2 = g1.getTime() - 1000000;
    //console.log(g1);
    //Meet Date Validation
    if (g2 >= this.state.meetDate.getTime()) {
      this.setState({
        errors: { meetDate: "Please enter valid meet date" },
      });
      return;
    } else {
      this.setState({
        errors: { meetDate: "" },
      });
    }

    //Deadline Date Validation
    if (this.state.meetDate.getTime() > this.state.deadline.getTime()) {
      this.setState({
        errors: { deadline: "Enter a valid deadline date" },
      });
      return;
    } else {
      this.setState({
        errors: { deadline: "" },
      });
    }

    const task = {
      faculty_name: this.state.fname,
      email: this.state.email,
      contact: this.state.contact,
      app_details: this.state.app_details,
      method: this.state.method,
      requirement: this.state.requirement,
      user: this.state.user,
      deadline: this.state.deadline,
      meetDate: this.state.meetDate,
      purpose: this.state.purpose,
      leader: " ",
      team: " ",
      status: "Requested",
    };
    axios
      .post(BACKEND_URL + "/tasks/", task)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ redirectToHome: true });
      })
      .catch((err) => {
        console.log(err);
        // Optional: You could also use a Swal alert for errors
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: true,
          timer: 3000,
        });
      });
  };

  //Utility function for logout
  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("index");
    this.setState({
      loggedOut: true,
      id: "",
    });
  };
  render() {
    if (this.state.redirectToHome) {
      return <Redirect to="/" />; 
    }
    return (
      <div className="App">
        <Header title="Project Details" type="form"></Header>
        <div>
          <i
            className="fa fa-arrow-left"
            style={{
              fontSize: "30px",
              marginLeft: "20px",
              marginTop: "5px",
              cursor: "pointer",
              color: "#505050",
            }}
            onClick={() => this.props.history.goBack()}
          ></i>
        </div>
        {/* <Link className=" logout text-light" onClick={this.logout}>Logout</Link> */}
        <div className="form-css">
          <form
            className="align-content-center form-group d-flex flex-column px-5 py-4"
            onSubmit={this.onSubmit}
          >
            <label>
              <b>Name</b>
            </label>
            <input
              type="text"
              name="fname"
              className="form-control border-dark form-text"
              value={this.state.fname}
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.fname}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Email Id</b>
            </label>
            <input
              type="email"
              name="email"
              value={this.state.email}
              className="form-control border-dark form-text"
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.email}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Contact Number</b>
            </label>
            <input
              type="text"
              name="contact"
              value={this.state.contact}
              className="form-control border-dark form-text"
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.contact}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Major Purpose</b>
            </label>
            <input
              type="text"
              name="purpose"
              className="form-control border-dark form-text"
              value={this.state.purpose}
              onChange={this.changeHandler}
            />
            <Error error={this.state.errors.purpose}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Application Details:</b>
              <br></br>
              <textarea
                rows="5"
                cols="60"
                name="app_details"
                value={this.state.app_details}
                className="form-control border-dark form-text"
                onChange={this.changeHandler}
                style={{
                  marginLeft: "1.8%",
                  marginTop: "0.8%",
                }}
              />
            </label>
            <Error error={this.state.errors.app_details}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Preferred Method</b>
            </label>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Website"
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Website</span>
            </div>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Web App"
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Web App</span>
            </div>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Mobile App"
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Mobile App</span>
            </div>
            <div className="mb-1">
              <input
                type="checkbox"
                value="Others"
                name="method"
                onChange={this.changeHandler}
              ></input>
              <span> Others</span>
            </div>
            <Error error={this.state.errors.method}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Meeting Date</b> <br></br>
              <DatePicker
                selected={this.state.meetDate}
                onChange={this.handleMeetDate}
                className="form-control border-dark form-text"
              />
            </label>
            <Error error={this.state.errors.meetDate}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>Requirements</b>
              <br></br>
              <textarea
                rows="3"
                cols="60"
                name="requirement"
                value={this.state.requirement}
                className="form-control border-dark form-text"
                onSelect={this.handleSelect}
                onChange={this.changeHandler}
                style={{
                  marginLeft: "1.8%",
                  marginTop: "0.8%",
                }}
              />
            </label>
            <Error error={this.state.errors.requirement}></Error>
            <div className="sizedBox mb-4"></div>
            <label>
              <b>User</b>
              <select
                value={this.state.user}
                className="form-control border-dark col-md-4form-text"
                onChange={this.changeHandler}
                style={{
                  marginLeft: "1.8%",
                  marginTop: "0.8%",
                }}
                name="user"
              >
                <option>Student</option>
                <option>Professor</option>
                <option>Others</option>
              </select>
            </label>
            <Error error={this.state.errors.user}></Error>
            <div className="sizedBox mb-4"></div>

            <label>
              <b>Deadline</b> <br></br>
              <DatePicker
                selected={this.state.deadline}
                onChange={this.handleDeadlineDate}
                className="form-control border-dark form-text"
                style={{
                  marginLeft: "1.8%",
                  marginTop: "0.8%",
                }}
              />
            </label>
            <Error error={this.state.errors.deadline}></Error>
            <div className="sizedBox mb-2"></div>
            <br></br>
            <input type="submit" value="Submit" className="btn btn-dark" />
          </form>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
export default Form;
