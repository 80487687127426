import React from "react";

function Footer() {
    return (
        <footer
            // style={{width: '96%'}}
            id="sticky-footer"
            className="text-white-50 bg-dark d-flex justify-content-center flex-column"
        >
            <div className="text-center">
                <small>Copyright &copy; SDS PORTAL</small>
                <br></br>
            </div>
        </footer>
    );
}

export default Footer;
